<template>
  <div>
    <section>
      <a id="lineup"><h1>Lineup</h1></a>
      <Lineup ref="lineup" class="mt-10"></Lineup>
    </section>
  </div>
</template>

<script>
import Lineup from "@/components/lineup2023/Lineup";

export default {
  name: '2023Lineup',
  components: {Lineup},
}
</script>

<style scoped lang="scss">
.dot {
  display: inline-block;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  background-color: red;
  margin-right: 5px;
}

section {
  margin-bottom: 50px;
}
</style>


