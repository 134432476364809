<template>
  <div>
    <h1>Ergebnisse 2021</h1>
    <Leaderboard :value="movies" :interactive="false"/>
  </div>
</template>

<script>
import Leaderboard from "@/components/Leaderboard";

export default {
  name: "2021Page",
  components: {
    Leaderboard,
  },
  data() {
    return {
      movies: [],
    }
  },
  mounted() {
    fetch('/2021.json')
        .then(res => res.json())
        .then(data => {
          this.movies = data.map((movie, index) => ({
            id: index,
            name: movie.title,
            poster: movie.poster,
            score: Math.round(movie.score),
          }))
        })
  }
}
</script>

<style scoped>

</style>
