<template>
  <div class="mb-5">
    <main>
      <div class="lineup">
        <LineupMovie
          v-if="currentMovie"
          class="now"
          :movie="currentMovie"
          up-next
        />
        <LineupMovie
          v-for="movie in lineup"
          ref="movie"
          :key="movie.title"
          :movie="movie"
        />
      </div>
    </main>
  </div>
</template>

<script>
import LineupMovie from "./LineupMovie";
import GalleryController from "@/components/lineup/galleryController";
import LocomotiveScroll from "locomotive-scroll";
import Splitting from "splitting";
import {getMovie, getTrailers} from "@/utils";
import LineupMovieUtils from "@/mixins/LineupMovieUtils.vue";

export default {
  name: "Lineup",
  components: {LineupMovie},
  mixins: [LineupMovieUtils],
  data() {
    return {
      galleryController: null,
      lineup: [
        {
          "title": "Jojo Rabbit",
          "date": "2022-01-28"
        },
        {
          "title": "Parasite",
          "date": "2022-02-04",
          year: 2019,
        },
        {
          "title": "Green Book",
          "date": "2022-03-04"
        },
        {
          "title": "The Revenant",
          "date": "2022-03-11"
        },
        {
          "title": "Movie 43",
          "date": "2022-03-18"
        },
        {
          "title": "Donnie Darko",
          "date": "2022-03-25",
        },
        {
          "title": "The Rock: Fels der Entscheidung",
          "date": "2022-04-01",
        },
        {
          "title": "Akira",
          "date": "2022-04-08",
          year: 1991
        },
        {
          "title": "American Psycho",
          "date": "2022-04-22",
        },
        {
          "title": "Shoplifters",
          "date": "2022-04-29",
          year: 2018
        },
        {
          "title": "Arrival",
          "date": "2022-05-06"
        },
        {
          "title": "Gran Torino",
          "date": "2022-05-27",
        },
        {
          "title": "Praxis Dr Hasenbein",
          "date": "2022-06-03"
        },
        {
          "title": "Memento",
          "date": "2022-07-22"
        },
        {
          "title": "Victoria",
          "date": "2022-07-29",
          year: 2015,
        },
        {
          "title": "Isle of Dogs",
          date: '2022-08-12',
        },
        {
          "title": "Once Upon a Time in Hollywood",
        },
        {
          "title": "The Death of Stalin",
        },
        {
          "title": "Waynes World",
        },
        {
          "title": "Lost in Translation",
          date: '2022-09-16',
        },
        {
          "title": "St. Vincent",
        },
        {
          "title": "Kiss the Cook",
          year: 2014,
        },
        {
          "title": "Mac and Me",
          date: '2022-10-28',
        },
        {
          "title": "Spaceballs",
          date: '2022-11-04',
        },
        {
          "title": "Palm Springs",
        },
        {
          "title": "Edge of Tomorrow",
        },
        {
          "title": "Der Herr der Ringe: Die Gefährten",
          apiTitle: 'Der Herr der Ringe: Die Gefährten',
        },
        {
          "title": "Der Herr der Ringe: Die zwei Türme",
        },
        {
          "title": "Der Herr der Ringe: Die Rückkehr des Königs",
        },
        {
          "title": "Watchmen",
        },
        {
          "title": "Die Ritter der Kokosnuss",
          date: '2022-12-30',
        }
      ],
    }
  },
  computed: {
    currentMovie () {
      return this.lineup.find(movie => this.isToday(movie.date) || this.isFuture(movie.date))
    }
  },
  created() {
    document.body.classList.add('noscroll')
  },
  mounted() {
    const promises = []
    this.lineup.forEach((movie, index) => {
      if (index > 0 && !movie.date) {
        const lastMovie = this.lineup[index - 1]
        const newDate = new Date(lastMovie.date)
        newDate.setDate(newDate.getDate() + 7)
        movie.date = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
      }
    })
    console.log(this.lineup)
    this.lineup.forEach(movie => {
      promises.push(getMovie(movie).then(async info => {
        movie.info = info
        if (movie.withTrailer !== false) {
          movie.trailers = await getTrailers(info.id)
        }
      }))
    })

    Splitting();

    Promise.all(promises).then(() => {
      // Initialize the Locomotive scroll
      const scroll = new LocomotiveScroll({
        el: document.querySelector('body'),
        smooth: true,
        offset: ["10%", "20%"],
        lerp: 0.13
      })
      // Initialize the GalleryController
      this.galleryController = new GalleryController(document.querySelector('.lineup'), scroll)

      const ls = localStorage.getItem('lineup.visited');
      if (!ls) {
        localStorage.setItem('lineup.visited', 1)
      }
    })
  },
  unmounted() {
    galleryController
  },
  updated() {
    // this.observe()
  },
  methods: {
    observe() {
      const observer = new IntersectionObserver(entries => {
        // isIntersecting is true when element and viewport are overlapping
        // isIntersecting is false when element and viewport don't overlap
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            let parent = entry.target
            while (!parent.classList.contains('slide-in')) {
              parent = parent.parentElement
            }
            this.becameVisible(parent)
          }
        })
      }, {threshold: 1});

      this.$nextTick().then(() => {
        setTimeout(() => {
          Array.from(document.querySelectorAll(".slide-in .slide-in-trigger")).forEach(element => {
            observer.observe(element)
          })
        }, 500)
      })
    },

    becameVisible(el) {
      el.classList.add('animate')
    },
  },
}
</script>

<style lang="scss">
@import "locomotive-scroll/src/locomotive-scroll";

.noscroll {
  overflow: hidden;
  height: 100vh;
}

.noscroll main {
  overflow: hidden;
  height: 100vh;
}
</style>

<style lang="scss" scoped>
main {
  display: grid;
  grid-template-areas: 'frame' 'content';
}

.lineup {
  /*overflow: hidden;*/
  grid-area: content;
  justify-self: center;
  width: 100%;
}

.now {
  padding-bottom: 40px;
  margin-bottom: 40px;
  margin-top: 0;
  border-bottom: 2px solid black;
}

.up-next {
  text-align: center;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: bold;
}
</style>
