import { createRouter, createWebHistory  } from 'vue-router'
import Index from './pages/index.vue'
import Archive from './pages/archive/index.vue'
import B2021 from './pages/archive/2021.vue'
import L2022 from './pages/archive/2022lineup.vue'
import L2023 from './pages/archive/2023lineup.vue'
import About from './pages/about.vue'
import Stream from './pages/stream.vue'

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/archive',
        name: 'Archiv',
        component: Archive
    },
    {
        path: '/archive/2021',
        name: '2021',
        component: B2021
    },
    {
        path: '/archive/2022',
        name: '2022 Lineup',
        component: L2022
    },
    {
        path: '/archive/2023',
        name: '2023 Lineup',
        component: L2023
    },
    {
        path: '/about',
        name: 'Über',
        component: About
    },
    {
        path: '/stream',
        name: 'Stream',
        component: Stream
    }
]

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes
})

export default router
