<template>
  <div>
    <div class="layout">
      <div class="header relative text-center">
        <RouterLink to="/">
          <div class="logo my-4 md:mt-16" />
        </RouterLink>
        <Navbar :items="menu" />
        <div
          class="absolute top-0 right-0 cursor-pointer"
        >
          <div
            v-if="isLoggedIn"
            class="flex flex-col items-center"
          >
            <UserMinusIcon
              class="h-8 w-8 inline-block"
              @click="logout"
            />
            <span class="hidden md:inline-block">{{ username }}</span>
          </div>
          <UserIcon
            v-else
            title="Einloggen"
            class="h-8 w-8"
            @click="showLogin"
          />
        </div>
      </div>
      <div class="page mb-60">
        <router-view v-slot="{ Component, route }">
          <!-- Use any custom transition and  to `fade` -->
          <transition :name="route.meta.transition || 'scale'">
            <component
              :is="Component"
              :key="route.path"
            />
          </transition>
        </router-view>
      </div>
      <div class="footer">
        <Footer />
      </div>
    </div>
    <transition name="scale">
      <Modal
        v-if="loginVisible"
        @close="loginVisible = false"
      >
        <Login @loggedIn="loginVisible = false; $forceUpdate()" />
      </Modal>
    </transition>
    <div
      class="background-overlay"
      :style="randomPosition"
    />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import {UserIcon, UserMinusIcon} from '@heroicons/vue/24/outline'
import Login from "@/components/Login.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: 'BertinaleLayout',
  components: {Footer, Navbar, UserIcon, UserMinusIcon, Login, Modal},
  data() {
    return {
      menu: [
        {
          text: 'Stream',
          link: '/stream',
        },
        {
          text: 'Lineup',
          link: {path: '/', hash: '#lineup'},
        },
        {
          text: 'Archiv',
          link: '/archive',
        },
        {
          text: 'Über das Festival',
          link: '/about',
        },
      ],
      loginVisible: false,
    }
  },
  computed: {
    isLoggedIn() {
      return !!this.$userdata.accessToken
    },
    username() {
      return this.$userdata.username
    },
    randomPosition() {
      let randomTop = 0
      let randomLeft = 0
      while ((randomTop > -100 && randomTop < 100 && randomLeft > -100 && randomLeft < 100)) {
        randomTop = (Math.random() - .5) * 400
        randomLeft = (Math.random() - .5) * 400
      }

      return {
        top: randomTop + 'vh',
        left: randomLeft + 'vw'
      }
    },
  },
  watch: {
    '$userdata': {
      deep: true,
      handler: function (val) {
        console.log(val)
        if (val) this.showLogin()
        else this.loginVisible = false
      }
    },
    '$route'() {
      document.title = this.$route.name
      if (this.$route.hash) {
        setTimeout(() => this.scrollTo(this.$route.hash), 1)
      }
    }
  },
  mounted: function () {
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1)
    }
  },

  methods: {
    showLogin() {
      if (!this.isLoggedIn) {
        this.loginVisible = true
      }
    },
    logout() {
      this.$userdata.setData(null)
      this.$forceUpdate()
    },
    scrollTo: function (hash) {

    },
  }
}
</script>

<style lang="scss">
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-9');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&display=swap');

body {
  transition: color 1s ease;
  font-size: 18px;
}

.layout {
  padding: 10px;
  margin: 0;
  width: 90vw;
  margin-inline: auto;
}

.logo {
  display: inline-block;
  width: 300px;
  height: 80px;
  background-image: url("../public/assets/BertinaleText.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.header {
  font-family: 'Helvetica Neue', sans-serif;
}

.menu {
  padding: 0;

  li {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;

    a {
      display: inline-block;
      padding: 10px 15px;
    }
  }
}

.logo, .ffs-logo {
  transition: filter 1s ease;
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.page {
  padding-top: .5rem;
}

.layout h1 {
  transition: color 1s ease;
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  padding-block: 2rem;
  letter-spacing: 2px;
}

.layout h2 {
  font-size: 50px;
}

.layout h3 {
  font-size: 30px;
  font-weight: lighter;
}

.link--eirene {
  cursor: pointer;
  position: relative;

  &.router-link-active {
    font-weight: bold;
  }

  &:hover {
    &::before {
      transition-timing-function: cubic-bezier(0.8, 0, 0.1, 1);
      transition-duration: 0.4s;
      opacity: 1;
      transform: scale3d(1.1, 0.1, 1);
    }

    span {
      transform: translate3d(0, 0, 0);
      transition-timing-function: cubic-bezier(0.8, 0, 0.1, 1);
      transition-duration: 0.4s;
      transition-delay: 0s;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    background: black;
    top: 100%;
    left: 0;
    pointer-events: none;
    height: 3px;
    border-radius: 20px;
    transform: scale3d(1, 1, 1);
    transition: transform 0.2s, opacity 0.2s, background 1s ease;
    transition-timing-function: cubic-bezier(0.2, 0.57, 0.67, 1.53);
  }

  span {
    transform: translate3d(0, -4px, 0);
    display: inline-block;
    transition: transform 0.2s 0.05s cubic-bezier(0.2, 0.57, 0.67, 1.53);
  }
}

.scale-enter-active, .scale-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
  height: 0;
  margin: 0;
}

.scale-enter-from, .scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.background-overlay {
  position: fixed;
  transition: all 1s ease;
  background: black;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 20%;
}

</style>
