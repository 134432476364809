<template>
  <div class="modal fixed w-full h-full top-0 left-0 z-20">
    <div
      class="modal-backdrop"
      @click="$emit('close')"
    />
    <div class="modal-content relative rounded-2xl shadow-2xl bg-white overflow-y-auto max-h-[80vh] mx-auto top-20 max-w-2xl w-11/12">
      <div
        class="modal-header pt-8"
      >
        <h1 class="text-4xl" v-if="title">
          {{ title }}
        </h1>
        <XMarkIcon
          class="cursor-pointer absolute right-4 top-4 w-8 h-8"
          @click="$emit('close')"
        />
      </div>
      <div class="modal-body pt-8 pb-16 max-w-md mx-auto">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import {XMarkIcon} from '@heroicons/vue/24/solid'

export default {
  name: "Modal",
  components: {XMarkIcon},
  props: ['title'],
  mounted() {
    this.$root.$el.style = 'overflow: hidden; max-height: 100vh; max-width: 100vw'
  },
  unmounted() {
    this.$root.$el.style = ''
  }
}
</script>

<style scoped>
.modal-backdrop {
  @apply absolute w-full h-full top-0 left-0 bg-red-100 bg-opacity-70 backdrop-blur transition-all;
}
</style>
