export async function getMovie(movie, first = true) {
    const url = new URL('https://api.themoviedb.org/3/search/movie?api_key=64a015ee94412bef16ea9ef83bf3b8e8')
    url.searchParams.append('query', movie.apiTitle || movie.title)
    url.searchParams.append('language', 'de-DE')
    if (movie.year_of_release) {
        movie.year = new Date(movie.year_of_release).getFullYear()
    }
    if (movie.year) {
        url.searchParams.append('primary_release_year', movie.year)
    }
    return await fetch(url.toString()).then(res => res.json()).then(data => first ? data.results[0] : data.results)
}

export async function getDetails(id) {
    const url = new URL(`https://api.themoviedb.org/3/movie/${id}?api_key=64a015ee94412bef16ea9ef83bf3b8e8`)
    return await fetch(url.toString())
        .then(res => res.json())
}

export async function getTrailers(id) {
    const url = new URL(`https://api.themoviedb.org/3/movie/${id}/videos?api_key=64a015ee94412bef16ea9ef83bf3b8e8`)
    return await fetch(url.toString())
        .then(res => res.json())
        .then(data => data.results)
        .then(videos => videos.filter(video => video.type === 'Trailer'))
}

export async function backendFetch (url, params) {
    url += '?' + new URLSearchParams(params)
    return fetch(process.env.VUE_APP_API_URL + url, {
        headers: {
            'X-API-Key': process.env.VUE_APP_API_KEY
        },
    })
}
