<template>
  <div>
    <Html>
      <Head>
        <Script src="https://embed.videodelivery.net/embed/sdk.latest.js" />
      </Head>
    </Html>
    <iframe
      id="stream-player"
      ref="player"
      src="https://iframe.videodelivery.net/bb93aee96301b79c60536b5f88ea7cb1"
      style="border: none;"
      :style="{ height: streamHeight + 'px'}"
      class="max-w-screen w-full"
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowfullscreen="true"
    />
    <!-- <VideoStream controls src="bb93aee96301b79c60536b5f88ea7cb1"/> -->
  </div>
</template>

<script>
export default {
  name: 'Stream',
  data () {
    return {
      streamHeight: 300
    }
  },
  created () {
  },
  mounted() {
    window.onload = () => {
      const stream = document.getElementById('stream-player')
      this.streamHeight = stream.width

      const player = Stream(stream)

      player.addEventListener('play', () => {
        console.log('playing!')
      })
      player.play().catch(() => {
        console.log('playback failed, muting to try again')
        player.muted = true
        player.play()
      })
    }
  }
}
</script>

<style scoped>

</style>
