<template>
  <div>
    <section>
      <a id="about"><h1>Über das Festival</h1></a>
      <p>Die Filmfestspiele Brunohl wurden 2021 ins Leben gerufen und gehören schon heute zu den
        angesehensten und exklusivsten Filmfestivals der Welt. Die herausragenden Juroren bestehen dabei aus
        langjährigen
        und engagierten Filmkennern. Es findet jedes halbe Jahr, das ganze halbe Jahr über statt.
      </p>

      <p>Anders als bei den als "Big Three" bekannten Festivals (Venedig, Cannes und Berlin) werden
        bei der Bertinale nicht nur Filme gezeigt, die neu sind. Neben solchen aus der Gegenwart werden auch
        Filme aus der Vergangenheit und sogar der Zukunft gezeigt.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'about'
}
</script>

<style scoped>

</style>
