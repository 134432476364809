<template>
  <div class="">
    <span class="bertinale-loading inline-block w-full h-full"/>
  </div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style scoped>
  .bertinale-loading {
    background-image: url("/public/assets/Roll.svg");
    background-position: center center;
    background-size: contain;
    background-repeat: repeat-y;
    background-color: white;
    animation: moveBackground 4s linear infinite;
  }

  @keyframes moveBackground {
    from {
      background-position: center 0;
    }
    to {
      background-position: center 5rem;
    }
  }
</style>
