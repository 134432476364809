<template>
  <div
    class="movie_card cursor-pointer"
    :style="{'--poster-accent-color': accentColor, '--poster-accent-color-dm': accentColorDm }"
  >
    <a
      :href="'https://www.imdb.com/title/' + details.imdb_id"
      target="_blank"
    >
      <div class="info_section">
        <div class="movie_header">
          <img
            class="locandina"
            :src="`https://image.tmdb.org/t/p/w500/${info.poster_path}`"
          >
          <h1
            class="m-0 whitespace-pre"
            style="padding: 0;"
          >
            {{ movie.title }}
          </h1>
          <h4>
            <span class="inline-flex items-center mr-2">
              <CalendarIcon
                class="inline-block h-5 w-5 mr-1"
              />
              {{ localDateString }}
            </span>
            <span
              class="inline-flex items-center mr-4"
            >
              <ClockIcon
                class="inline-block h-5 w-5 mr-1"
              />
              {{ details.runtime }} Minuten
            </span>
          </h4>
          <h4 class="mt-2">
            <span
              v-if="isPast(movie.date)"
              class="inline-flex items-center mr-2  bg-gradient-to-r from-green-700 to-green-500 rounded-full px-4 py-1"
            ><CheckBadgeIcon
              class="inline-block h-5 w-5 mr-2"
            />DONE</span>
            <span
              v-else-if="isToday(movie.date)"
              class="inline-flex items-center mr-2 bg-gradient-to-r from-red-700 to-red-500 rounded-full px-4 py-1"
            ><SparklesIcon
              class="inline-block h-5 w-5 mr-2"
            />NOW</span>
            <span
              v-else-if="isCurrent(movie) || upNext"
              class="inline-flex items-center mr-2 bg-gradient-to-r from-amber-700 to-amber-500 rounded-full px-4 py-1"
            ><TicketIcon
              class="inline-block h-5 w-5 mr-2"
            />UP NEXT</span>
          </h4>
        </div>
        <div class="movie_desc">
          <p
            class="text"
          >
            {{ info.overview }}
          </p>
        </div>
      </div>

      <div
        class="blur_back"
        :style="{'background-image': `url(https://image.tmdb.org/t/p/w500${info.backdrop_path})`}"
      />
    </a>
  </div>
</template>

<script>
import {CalendarIcon, ClockIcon, CheckBadgeIcon, TicketIcon, SparklesIcon} from "@heroicons/vue/24/outline";
import Vibrant from "node-vibrant/lib/bundle";
import {getDetails, getMovie} from "@/utils";
import LineupMovieUtils from "@/mixins/LineupMovieUtils.vue";

async function getAccentColorFromImage(info) {
  const url = `https://image.tmdb.org/t/p/w500/${info.backdrop_path}`
  const vibrant = new Vibrant(url, {})
  return await vibrant.getPalette()
}

export default {
  name: "LineupMovie",
  components: {CalendarIcon, ClockIcon, CheckBadgeIcon, TicketIcon, SparklesIcon},
  mixins: [LineupMovieUtils],
  props: {
    movie: {
      type: Object,
      required: true,
    },
    upNext: Boolean,
  },
  async setup(props) {
    const info = await getMovie(props.movie)
    const details = await getDetails(info.id)
    const palette = await getAccentColorFromImage(info)
    const accentColor = `rgba(${palette.DarkVibrant.rgb.join(',')}, 1)`
    const accentColorDm = `rgba(${palette.DarkMuted.rgb.join(',')}, 0.5)`
    return {
      info,
      details,
      accentColor,
      accentColorDm
    }
  },
  data() {
    return {

    }
  },
  computed: {
    localDateString() {
      return new Date(this.movie.date).toLocaleDateString()
    }
  },
  methods: {
  }
}
</script>


<style scoped lang="scss">
.movie_card {
  --poster-accent-color: rgba(80, 80, 80, 0.5);
  --poster-accent-color-dm: rgba(38, 38, 38, 0.5);
  position: relative;
  display: block;
  width: 1000px;
  max-width: 100%;
  height: 450px;
  margin: 5rem auto 10rem;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.4s;
  box-shadow: 0px 0px 150px -45px var(--poster-accent-color);

  &.now {
    margin-top: 0;
    margin-bottom: 5rem;
  }

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    transform: scale(1.02);
    transition: all 0.4s;
    box-shadow: 0px 0px 120px -55px var(--poster-accent-color);
  }

  .info_section {
    position: relative;
    width: 100%;
    height: 100%;
    background-blend-mode: multiply;
    z-index: 2;
    border-radius: 10px;

    .movie_header {
      position: relative;
      padding: 25px;
      height: 40%;

      h1 {
        color: #fff;
        font-weight: 400;
        text-align: left;
        font-size: clamp(2rem, 3.5vw, 2.75rem);
      }

      h4 {
        font-weight: 400;
      }

      .minutes {
        display: inline-block;
        margin-top: 10px;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.13);
      }

      .type {
        display: inline-block;
        color: #cee4fd;
        margin-left: 10px;
      }

      .locandina {
        position: relative;
        float: left;
        margin-right: 20px;
        height: 120px;
        box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
      }
    }

    .movie_desc {
      padding: 0 2rem 2rem;
      height: 50%;

      .text {
        color: #cfd6e1;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        word-break: break-word;
      }
    }

    .movie_social {
      height: 10%;
      padding-left: 15px;
      padding-bottom: 20px;

      ul {
        list-style: none;
        padding: 0;

        li {
          display: inline-block;
          color: rgba(255, 255, 255, 0.4);
          transition: color 0.3s;
          transition-delay: 0.15s;
          margin: 0 10px;

          &:hover {
            transition: color 0.3s;
            color: rgba(255, 255, 255, 0.8);
          }

          i {
            font-size: 19px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .blur_back {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 11px;
  }
}

@media screen and (min-width: 768px) {
  .movie_header {
    width: 60%;
  }

  .movie_desc {
    width: 50%;
  }

  .info_section {
    background: linear-gradient(to right, #0d0d0c 50%, var(--poster-accent-color-dm) 100%);
  }

  .blur_back {
    width: 80%;
    background-position: 135px 10% !important;
  }
}

@media screen and (max-width: 768px) {
  .movie_card {
    width: 95%;
    margin: 70px auto;
    min-height: 350px;
    height: auto;
  }

  .blur_back {
    width: 100%;
    background-position: 50% 50% !important;
  }

  .movie_header {
    width: 100%;
    margin-top: 85px;
  }

  .movie_desc {
    width: 100%;
  }

  .info_section {
    background: linear-gradient(to top, #0d0d0c 50%, var(--poster-accent-color-dm) 100%);
    display: inline-grid;

    h1 {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 768px) {
  .movie_card {
    width: 100%;

    .info_section {
      .movie_header {
        padding: 1rem;
      }

      .movie_desc {
        padding: 0 1rem 1rem;
      }
    }

    h1 {
      white-space: normal;
    }
  }
}
</style>
