<template>
  <div>
    <h1>Archiv</h1>

    <div class="flex flex-wrap">
      <RouterLink to="/archive/2021"
                  class="w-full md:w-1/4"
      >
        <div class="bg-cover bg-no-repeat"
             style="padding-top: 140%; background-image: url('assets/2021 Draft D.png');"></div>
      </RouterLink>
      <RouterLink to="/archive/2022"
                  class="w-full md:w-1/4"
      >
        <div class="bg-cover bg-no-repeat"
             style="padding-top: 140%;">Bertinale 2022</div>
      </RouterLink>
      <RouterLink to="/archive/2023"
                  class="w-full md:w-1/4"
      >
        <div class="bg-cover bg-no-repeat"
             style="padding-top: 140%;">Bertinale 2023</div>
      </RouterLink>
    </div>
  </div>
</template>

<script>
export default {
  name: "Archive"
}
</script>

<style scoped>

</style>
