<template>
  <div class="lineup2023">
    <Suspense timeout="0">
      <template #default>
        <div>
          <h2 class="text-center mb-10">
            Up next:
          </h2>
          <LineupMovie
            v-if="currentMovie"
            class="now"
            :movie="currentMovie"
            up-next
          />
          <hr>
          <LineupMovie
            v-for="(movie, movieKey) in lineup"
            :key="movieKey"
            :movie="movie"
          />
        </div>
      </template>
      <template #fallback>
        <div
          class="flex flex-col justify-center items-center mx-auto text-2xl text-center"
        >
          <Loading class="w-16 h-20" />
          <span class="pt-2">Lädt...</span>
        </div>
      </template>
    </Suspense>
  </div>
</template>

<script>
import LineupMovieUtils from "@/mixins/LineupMovieUtils.vue";
import LineupMovie from "@/components/lineup2023/LineupMovie.vue";
import Loading from "@/components/Loading.vue";
import {onErrorCaptured, ref} from "vue";

export default {
  name: "Lineup",
  components: {Loading, LineupMovie,},
  mixins: [LineupMovieUtils],
  setup() {
    const error = ref(null)
    onErrorCaptured(e => {
      error.value = e
      console.log(error)
      return true
    })
    return {
      error
    }
  },
  data() {
    return {
      lineup: [
        {
          title: "Ein Königreich für ein Lama",
          date: "2023-01-27"
        },
        {"title": "Oldboy", year: 2003},
        {"title": "Tropic Thunder"},
        {"title": "Shaun of the Dead"},
        {
          "title": "Blair Witch Project",
          date: "2023-03-10"
        },
        {
          "title": "Everything Everywhere All at Once",
          date: "2023-03-17"
        },
        {
          "title": "Le Mans 66 - Gegen jede Chance",
          date: "2023-03-31"
        },
        {
            "title": "Hot Dog",
            date: "2023-04-28",
            year: 2018,
        },
        {
          "title": "Prinzessin Mononoke", date: "2023-05-12"
        },
        {"title": "Top Gun", year: 1986},
        {
          "title": "Top Gun: Maverick",
        },
        {
          "title": "Triangle of Sadness", date: "2023-06-23"
        },
        {"title": "Full Metal Jacket", date: "2023-06-30"},
        {"title": "Der Blade Runner"},
        {
          "title": "Redline",
          year: 2010,
          date: "2023-07-14",
        },
        {
          "title": "Oppenheimer",
          date: "2023-07-21",
        },
        {
          "title": "Barbie",
          date: "2023-07-21",
        },
        {
          "title": "Fitzcarraldo",
          date: "2023-07-28",
        },
        {
          "title": "Pans Labyrinth",
          date: "2023-08-04",
        },
        {"title": "City of God"},
        {"title": "Manta, Manta"},
        {"title": "Der Schuh des Manitu"},
        {"title": "The Big Lebowski", date: "2023-09-29"},
        {"title": "Licorice Pizza", date: "2023-10-13"},
        {"title": "Straight Outta Compton", date: "2023-10-06"},
        {"title": "American Pie", date: "2023-10-27", year: 1999},
      ]
    }
  },
  computed: {
    currentMovie() {
      return this.lineup.find(movie => this.isToday(movie.date) || this.isFuture(movie.date))
    }
  },
  created() {
    this.lineup.forEach((movie, index) => {
      if (index > 0 && !movie.date) {
        const lastMovie = this.lineup[index - 1]
        const newDate = new Date(lastMovie.date)
        newDate.setDate(newDate.getDate() + 7)
        movie.date = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
      }
    })
  },
  mounted() {
    document.body.classList.add('bertinale-2023')

  },
  unmounted() {
    document.body.classList.remove('bertinale-2023')
  },
  methods: {},
}
</script>


<style lang="scss">
.bertinale-2023, .bertinale-2023 .modal {
  color: #fff;

  .layout h1, .layout h2 {
    color: goldenrod;
    text-shadow: 0 0 10px #daa52075;
  }

  .footer-content {
    background: black;
  }

  .logo, .ffs-logo {
    filter: invert(1);
  }

  .voting-item {
    border-bottom: 2px solid gold;
  }

  .link--eirene:before {
    background: linear-gradient(45deg, #ffd700 20%, #ffaa0d);
    background: goldenrod;
  }

  .gallery__item-title[data-v-1b95cb66] {
    font-size: clamp(2rem, 10vw, 5rem);
    text-shadow: 0 0 4px #ffd700;
  }

  .background-overlay {
    top: 0 !important;
    left: 0 !important;
    border-radius: 0;
  }

  .bertinale-button.primary {
    @apply border-amber-500 text-amber-500;

    &:hover, &[data-checked="true"] {
      @apply text-white border-amber-600 shadow-amber-600;
    }
  }

  .bertinale-button.primary::after {
    @apply bg-gradient-to-r from-amber-700 to-amber-500;
  }

  .bertinale-button.secondary {
    @apply border-gray-300 text-gray-300;

    &:hover, &[data-checked="true"] {
      @apply text-black border-white shadow-white;
    }
  }

  .bertinale-button.secondary::after {
    @apply bg-gradient-to-r from-gray-300 to-white;
  }

  .modal-content {
    background: black;
  }

  .modal-backdrop {
    @apply bg-opacity-30 bg-amber-400 bg-gradient-to-br from-amber-500 to-amber-200 opacity-30;
  }
}

</style>
