<template>
  <figure
    class="gallery__item"
    :class="{ 'open': open }"
  >
    <div
      v-if="movie.info"
      class="gallery__item-img"
      @click="open = !open"
    >
      <div
        class="gallery__item-imginner"
        :style="{backgroundImage: `url('https://image.tmdb.org/t/p/w500/${movie.info.poster_path}')`}"
      />
    </div>
    <figcaption class="gallery__item-caption">
      <h2
        class="gallery__item-title"
        data-scroll
        data-scroll-speed="2"
        data-splitting
      >
        {{ movie.title }}
      </h2>
      <div
        class="overlay"
        data-scroll-speed="2"
      >
        <div class="gallery__item-number relative">
          <button
            class="absolute right-0 bottom-full cursor-pointer"
            @click="open = false"
          >
            &times;
          </button>
          <span>{{ formatDate(movie.date) }}</span>
          <span
            v-if="isPast(movie.date)"
            class="tag"
          >DONE</span>
          <span
            v-if="isToday(movie.date)"
            class="tag red"
          >NOW</span>
          <span
            v-else-if="isCurrent(movie) || upNext"
            class="tag green"
          >UP NEXT</span>
        </div>
        <p
          v-if="movie.info && movie.title !== 'Tenet aber rückwärts'"
          class="gallery__item-text"
        >
          {{ movie.info.overview }}
        </p>
        <p
          v-else-if="movie.info"
          class="gallery__item-text"
        >
          {{ [...movie.info.overview].reverse().join("") }}
        </p>
        <div
          v-if="movie.trailers && movie.trailers.length > 0"
          style="margin-top: 20px"
        >
          <h3>Trailer</h3>
          <iframe
            v-if="open"
            class="trailer"
            height="315"
            :src="'https://www.youtube.com/embed/' + movie.trailers[0].key"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>
      </div>
    </figcaption>
  </figure>
</template>

<script>
import LineupMovieUtils from "@/mixins/LineupMovieUtils.vue";

export default {
  name: "LineupMovie",
  mixins: [LineupMovieUtils],
  props: {
    movie: {
      type: Object,
      required: true,
    },
    upNext: Boolean,
  },
  data() {
    return {
      open: false,
    }
  },
}
</script>

<style>
.gallery__item-title span.whitespace {
  width: .3em;
}

.gallery__item-title span.char {
  display: inline-block;
  will-change: transform;
}
</style>

<style lang="scss" scoped>
.tag {
  display: inline-block;
  padding: 5px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-size: 12px;

  &.green {
    background-color: darkgreen;
  }

  &.red {
    background-color: red;
  }
}

.gallery__item {
  position: relative;
  margin: 25vh 0;
  display: grid;
  grid-template-areas: 'gallery-caption' 'gallery-image';
  grid-template-columns: 100%;

  .gallery__item-title,
  .gallery__item-img {
    transition: filter cubic-bezier(0.76, 0.46, 0, 1.32) 0.5s;
    filter: blur(0px);
  }

  &.open {
    .gallery__item-title,
    .gallery__item-img {
      filter: blur(5px);
    }

    .overlay {
      opacity: 1;
      transform: scale(1);
      pointer-events: all;
    }
  }
}

.gallery__item-img {
  grid-area: gallery-image;
  width: calc(100vw - 30vw);
  max-width: 375px;
  overflow: hidden;
  position: relative;
  will-change: transform, opacity;
  cursor: pointer;
  margin: 0 auto;
}

.noscroll .gallery__item-img {
  cursor: default;
}

.gallery__item-imginner {
  transition: filter ease 1s;
  background-size: cover;
  background-position: 50% 0;
  width: 100%;
  padding-bottom: 140%;
  filter: grayscale(1);
  opacity: 0.8;

  &:hover {
    filter: grayscale(0.5);
  }
}

.gallery__item-caption {
  grid-area: gallery-caption;
  padding: 0 0 1rem;
}

.gallery__item-title {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 10vw;
  font-family: 'PT Serif', serif;
  font-weight: 100;
  display: flex;
  justify-content: center;
  margin: 0;
  bottom: 15%;
  pointer-events: none;
  flex-flow: wrap;
}

.overlay {
  transition: cubic-bezier(0.76, 0.46, 0, 1.32) 0.5s;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  min-width: 250px;
  margin: 0 auto;
  padding: 8%;
  transform: scale(.8);
  z-index: 101;
  background: linear-gradient(145deg, #594444AA, #743030AA);
  pointer-events: none;
  color: white;
  border-radius: 10px;
  box-shadow: 20px 20px 60px #d9d9d9,
  -20px -20px 60px #ffffff;
}

.gallery__item-number {
  font-weight: 100;
  font-size: 2.5rem;
  border-bottom: 1px solid #494444;
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

.gallery__item-text {
  margin: 0;
}

.trailer {
  width: 100%;
  margin-inline: auto;
}

@media screen and (min-width: 53em) {
  .trailer {
    width: 560px;
  }

  .gallery__item {
    grid-template-areas: 'gallery-filler gallery-image gallery-caption';
    grid-template-columns: 25vw auto 25vw;
  }
  .gallery__item-caption {
    padding: 0 0 0 1rem;
  }
  .overlay {
    padding: 10% 20%;
  }
}
</style>
