<template>
  <div>
    <div class="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div class="w-full max-w-md space-y-8">
        <div>
          <h2 class="mt-6 text-center text-3xl font-bold tracking-tight">
            Loggen Sie sich ein!
          </h2>
        </div>
        <form
          class="mt-8 space-y-6"
          action="#"
          method="POST"
          @submit.prevent="submit"
        >
          <div class="-space-y-px rounded-md shadow-sm">
            <div>
              <label
                for="email-address"
                class="sr-only"
              >E-Mail Adresse</label>
              <input
                id="email-address"
                v-model="loginData.username"
                name="email"
                type="email"
                autocomplete="email"
                required
                class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="E-Mail Adresse"
              >
            </div>
            <div>
              <label
                for="password"
                class="sr-only"
              >Passwort</label>
              <input
                id="password"
                v-model="loginData.password"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Passwort"
              >
            </div>
          </div>

          <div class="text-center">
            <button
              class="bertinale-button primary"
              type="submit"
            >
              <span>
                <Loading
                  v-if="loading"
                  class="inline-block h-5 w-5 mr-1"
                />
                <LockClosedIcon
                  v-else
                  class="inline-block h-5 w-5 mr-1"
                />
                <span>Einloggen</span>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {LockClosedIcon} from '@heroicons/vue/24/solid'
import Loading from "@/components/Loading.vue";

export default {
  name: "Login",
  components: {Loading, LockClosedIcon},
  data() {
    return {
      loading: false,
      loginData: {
        username: "",
        password: ""
      },
    }
  },
  methods: {
    submit() {
      this.loading = true
      let url = process.env.VUE_APP_API_URL

      fetch(url + '/login', {
        body: JSON.stringify(this.loginData),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
          .then((response) => {
            this.loginData.resendCode = false
            if (response.status === 409 || response.status === 503 || response.status === 401) {
              throw new Error('' + response.status)
            }
            return response
          })
          .then(response => response.json())
          .then((response) => {
            const data = response.data
            if (!response.success) {
              const error = new Error()
              error.errors = response.errors
              throw error
            }
            if (data.authenticated && data.accessToken !== undefined) {
              this.$emit('loggedIn')
              this.$userdata.setData(data)
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
