<template>
  <div class="lineup2024">
    <Suspense timeout="0">
      <template #default>
        <div>
          <h1 class="font-mono uppercase text-center mb-0 pb-0">
            Up next
          </h1>
          <LineupMovie
            v-if="currentMovie"
            class="now"
            :movie="currentMovie"
            up-next
          />
          <hr class="border-bertinale2024-100">
          <LineupMovie
            v-for="(movie, movieKey) in lineup"
            :key="movieKey"
            :movie="movie"
          />
        </div>
      </template>
      <template #fallback>
        <div
          class="flex flex-col justify-center items-center mx-auto text-2xl text-center"
        >
          <Loading class="w-16 h-20" />
          <span class="pt-2">Lädt...</span>
        </div>
      </template>
    </Suspense>
  </div>
</template>

<script>
import LineupMovieUtils from "@/mixins/LineupMovieUtils.vue";
import LineupMovie from "@/components/lineup2024/LineupMovie.vue";
import Loading from "@/components/Loading.vue";
import {onErrorCaptured, ref} from "vue";
import {backendFetch} from "@/utils";

export default {
  name: "Lineup",
  components: {Loading, LineupMovie,},
  mixins: [LineupMovieUtils],
  setup() {
    const error = ref(null)
    onErrorCaptured(e => {
      error.value = e
      console.log(error)
      return true
    })
    return {
      error
    }
  },
  data() {
    return {
      lineup: [
      ]
    }
  },
  computed: {
    currentMovie() {
      return this.lineup.find(movie => this.isToday(movie.date) || this.isFuture(movie.date))
    }
  },
  async created() {
    await this.fetchMovies()

    this.lineup.forEach((movie, index) => {
      if (movie.show_date) {
        movie.date = movie.show_date
      }
      let newDate = new Date()
      if (index > 0) {
        const lastMovie = this.lineup[index - 1]
        newDate = new Date(lastMovie.date)
      }
      newDate.setDate(newDate.getDate() + 7)
      movie.date = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`
      movie.nr = index + 1
    })
  },
  mounted() {
    document.body.classList.add('bertinale-2024')

  },
  unmounted() {
    document.body.classList.remove('bertinale-2024')
  },
  methods: {
    async fetchMovies() {
      const params = {
        sortOrder: 'asc',
        sortField: 'show_date',
        results: -1,
        pre_filters: JSON.stringify([
          {
            field: 'festival_name',
            operator: '=',
            value: "Bertinale 2024"
          },
          {
            field: 'total_votes',
            operator: '>=',
            value: 3,
          },
        ])
      }
      const response = await backendFetch('/modules/bertinale_movies/resources', params)
      const data = await response.json()
      this.lineup = data.data
    }
  },
}
</script>


<style lang="scss">
@import "@/styles/2024";

</style>
