<template>
  <div class="text-center">
    <Bars3Icon
      class="cursor-pointer inline-block h-8 w-8 md:hidden"
      @click="menuOpen = !menuOpen"
    />
    <div class="overflow-hidden">
      <transition name="slide-fade">
        <menu
          v-if="menuOpen"
          class="menu mt-3 md:block"
        >
          <ul class="flex justify-center md:justify-between flex-wrap whitespace-nowrap">
            <li
              v-for="item in items"
              :key="item.text"
              class="w-3/4 md:flex-1 mb-4"
              @click="isMobile ? menuOpen = false : null"
            >
              <RouterLink
                class="link--eirene w-full"
                :to="item.link"
              >
                <span>{{ item.text }}</span>
              </RouterLink>
            </li>
          </ul>
        </menu>
      </transition>
    </div>
  </div>
</template>

<script>
import {Bars3Icon} from '@heroicons/vue/20/solid'

export default {
  name: "Navbar",
  components: {Bars3Icon},
  props: ['items'],
  data() {
    return {
      isMobile: window.innerWidth < 768,
      menuOpen: window.innerWidth >= 768,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768
    }
  }
}
</script>

<style>

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
