<template>
  <a
    :href="'https://www.imdb.com/title/' + details.imdb_id"
    target="_blank"
  >
    <div
      class="ticket"
      :style="{'--poster-accent-color': accentColor, '--poster-accent-color-dm': accentColorDm, '--poster-accent-color-vibrant': accentColorVib, '--poster-accent-color-muted': accentColorMut }"
    >
      <div class="holes-top holes-top-1 bg-bertinale2024-400 before:bg-bertinale2024-400 after:bg-bertinale2024-400" />
      <div class="holes-top holes-top-2 bg-bertinale2024-400 before:bg-bertinale2024-400 after:bg-bertinale2024-400" />
      <div class="holes-top holes-top-3 bg-bertinale2024-400 before:bg-bertinale2024-400 after:bg-bertinale2024-400" />
      <div class="bg-bertinale2024-500">
        <div class="title">
          <p class="movie-title text-5xl overflow-hidden overflow-ellipsis">
            {{ movie.title }}
          </p>
        </div>
        <div class="poster">
          <img
            :src="`https://image.tmdb.org/t/p/w500/${info.backdrop_path}`"
            :alt="movie.title"
          >
        </div>
        <div class="info">
          <table>
            <tr>
              <th>NUMBER</th>
              <th>SHOW DATE</th>
              <th>GENRE</th>
            </tr>
            <tr>
              <td class="bigger">
                #{{ movie.nr }}
              </td>
              <td class="bigger">
                {{ showDateString }}
              </td>
              <td class="bigger">
                {{ details.genres[0].name }}
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <th>RATING</th>
              <th>RELEASE DATE</th>
              <th>RUNTIME</th>
            </tr>
            <tr>
              <td>{{ details.vote_average.toFixed(1) }}</td>
              <td>{{ releaseDateString }}</td>
              <td>{{ runtime }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="holes-wrapper cutting-line">
        <div class="holes-lower holes-lower-1 bg-bertinale2024-400" />
        <div class="holes-lower holes-lower-2 bg-bertinale2024-400" />
      </div>
      <div
        class="serial p-10"
        :class="isPast(movie.date) ? 'done relative mb-40' : ''"
      >
        <div v-if="isPast(movie.date)">
          <div class="holes-rip holes-rip-1 bg-bertinale2024-400" />
          <div class="holes-rip holes-rip-2 bg-bertinale2024-400" />
        </div>
        <table class="barcode overflow-hidden">
          <tr>
            <td
              v-for="num in code"
              :class="num === '1' ? 'bg-black': 'white'"
            />
          </tr>
        </table>
        <p class="numbers text-sm md:text-lg text-center">
          {{ numbers }}
        </p>
      </div>
    </div>
  </a>
</template>

<script>
import Vibrant from "node-vibrant/lib/bundle";
import {getDetails, getMovie} from "@/utils";
import LineupMovieUtils from "@/mixins/LineupMovieUtils.vue";

async function getAccentColorFromImage(path) {
  const url = `https://image.tmdb.org/t/p/w500/${path}`
  const vibrant = new Vibrant(url, {})
  return await vibrant.getPalette()
}

export default {
  name: "LineupMovie",
  mixins: [LineupMovieUtils],
  props: {
    movie: {
      type: Object,
      required: true,
    },
    upNext: Boolean,
  },
  async setup(props) {
    const info = await getMovie(props.movie)
    const details = await getDetails(info.id)
    const palette = await getAccentColorFromImage(info.backdrop_path)
    const accentColor = `rgba(${palette.DarkVibrant.rgb.join(',')}, 1)`
    const accentColorDm = `rgba(${palette.DarkMuted.rgb.join(',')}, 1)`
    const accentColorVib = `rgba(${palette.Vibrant.rgb.join(',')}, 1)`
    const accentColorMut = `rgba(${palette.Muted.rgb.join(',')}, 1)`
    return {
      info,
      details,
      accentColor,
      accentColorDm,
      accentColorVib,
      accentColorMut
    }
  },
  data() {
    return {}
  },
  computed: {
    code() {
      return this.movie.title.split('').slice(0, 15).map(char => char.charCodeAt(0).toString(2).padStart(8, '0')).join('')
    },
    numbers() {
      return this.movie.title.split('').slice(0, 10).map(char => char.charCodeAt(0).toString(10)).join('')
    },
    creator() {
      const creator = (this.movie?.created_by?.email ?? '').split('.')[0]
      return ['a', 'e', 'i', 'o', 'u'].includes(creator.charAt(0).toLowerCase()) ? `an ${creator}` : `a ${creator}`
    },
    showDateString() {
      return new Date(this.movie.date).toLocaleDateString("en-US", {year: '2-digit', month: '2-digit', day: '2-digit'})
    },
    releaseDateString() {
      return new Date(this.details.release_date).toLocaleDateString("en-US", {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      })
    },
    runtime() {
      return parseInt(this.details.runtime / 60) + ":" + (this.details.runtime % 60 < 10 ? '0' : '') + this.details.runtime % 60
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
$width: 400;
$circle-size: 50;

.ticket {
  width: $width + px;
  margin: 5rem auto;
  position: relative;
  font-family: monospace;
  max-width: 100%;

  .holes-top {
    height: $circle-size + px;
    width: $circle-size + px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    margin-left: calc($circle-size / -2) + px;
    top: calc($circle-size / -2) + px;
  }

  .holes-top-1 {
    left: 0;
  }

  .holes-top-2 {
    left: 50%;
  }

  .holes-top-3 {
    left: 100%;
  }

  .holes-wrapper {
    position: relative;
    margin: 0 25px;
  }

  .cutting-line {
    border: 1px dashed #aaa;
  }

  .holes-lower, .holes-rip {
    height: $circle-size + px;
    width: $circle-size + px;
    position: absolute;
    border-radius: 50%;
  }

  .holes-lower-1 {
    top: -25px;
    left: calc($circle-size / -1) + px;
  }

  .holes-rip-1 {
    top: -25px;
    left: calc($circle-size / -2) + px;
  }

  .holes-lower-2 {
    top: -25px;
    right: calc($circle-size / -1) + px;
  }

  .holes-rip-2 {
    top: -25px;
    right: calc($circle-size / -2) + px;
  }

  .title {
    padding: 50px 25px 10px;
  }

  .cinema {
    color: #aaa;
    font-size: 22px;
  }

  .movie-title {
    color: var(--poster-accent-color-vibrant);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .info {
    padding: 15px 25px;

    table {
      @apply text-sm md:text-lg;

      width: 100%;
      margin-bottom: 15px;

      tr {
        margin-bottom: 10px;
      }

      th, td {
        text-align: left;

        &:nth-of-type(1) {
          width: 23%;
        }

        &:nth-of-type(2) {
          width: 45%;
        }

        &:nth-of-type(3) {
          width: 32%;
        }
      }

      td {
        @apply text-xl md:text-3xl;
      }
    }
  }

  .poster img {
    filter: saturate(150%) contrast(150%);
  }

  p, td {
    @apply uppercase;
  }

  td {
    @apply tracking-tighter;
  }

  .bigger {
    @apply text-2xl md:text-4xl;
  }

  .serial {
    background: var(--poster-accent-color-vibrant);

    &.done {
      transform: rotate(-30deg);
      transform-origin: top right;
    }

    table {
      border-collapse: collapse;
      margin: 0 auto;
    }

    td {
      width: 3px;
      height: 50px;
    }

    td.white {
      background: var(--poster-accent-color-vibrant);
    }
  }
}
</style>
