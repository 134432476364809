<template>

</template>

<script>
export default {
  name: "LineupMovieUtils",
  methods: {
    getWeekNumber(d) {
      // Copy date so don't modify original
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
      // Get first day of year
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
      // Calculate full weeks to nearest Thursday
      var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
      // Return array of year and week number
      return [d.getUTCFullYear(), weekNo]
    },

    sameWeek(d1, d2) {
      const w1 = this.getWeekNumber(d1)
      const w2 = this.getWeekNumber(d2)
      return w1[0] === w2[0] && w1[1] === w2[1]
    },

    isPast(date) {
      return !this.isToday(date) && new Date().getTime() > new Date(date).getTime()
    },

    isFuture(date) {
      return !this.isToday(date) && !this.isPast(date)
    },

    isToday(date) {
      date = new Date(date)
      const today = new Date()
      return today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear()
    },

    formatDate(date) {
      date = new Date(date)
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    },

    isCurrent(movie) {
      return this.sameWeek(new Date(movie.date), new Date())
    },

  }

}
</script>

<style scoped>
</style>
