import {computed, createApp, reactive} from 'vue'
import App from './bertinale.vue'
import router from './router'
import './index.css'

const app = createApp(App).use(router)
const userdata = reactive({
    loginVisible: false,
    data: JSON.parse(localStorage.getItem('user')),
    username: computed(() => userdata.data?.userName),
    accessToken: computed(() => userdata.data?.accessToken),
    userId: computed(() => userdata.data?.userId),
    setData: (value) => {
        userdata.data = value
        if (value) {
            localStorage.setItem('user', JSON.stringify(value))
        } else {
            localStorage.removeItem('user')
        }
        userdata.loginVisible = false
    },
});
app.config.globalProperties.$userdata = userdata

app.mount('#app')
