<template>
  <div class="w-100 footer-content px-10 pb-4 bg-white">
    <div class="h-2 bg-black mb-4 rounded-full" />
    <div class="flex justify-end items-center gap-5">
      <img
        class="ffs-logo"
        src="/FFSText.png"
        alt="Filmfestspiele Brunohl"
        width="150"
      >
      <a href="https://www.themoviedb.org/">
        <img
          width="50"
          src="https://www.themoviedb.org/assets/2/v4/logos/v2/blue_square_2-d537fb228cf3ded904ef09b136fe3fec72548ebc1fea3fbbd1ad9e36364db38b.svg"
          alt="The Movie DB"
        >
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer-content {
  transition: background 1s ease;
}
</style>
