<template>
  <div class="leaderboard__item" :class="{ selected: selected }" @click="focus">
    <div class="leaderboard__meta">
      <span class="leaderboard__rank">{{ delayedRank }}</span>
      <div class="leaderboard__poster" v-if="info">
        <img :src="'https://image.tmdb.org/t/p/w500/' + info.poster_path" alt=""/>
      </div>
      <span class="leaderboard__movie">{{ movie.name }}</span>
    </div>
    <div class="leaderboard__score">
      {{
        movie.score
      }}<input
        ref="newScore"
        v-model="newStep"
        @keyup="typeScore"
        style="width: 0px; height: 0px; opacity: 0"
    />
    </div>
  </div>
</template>

<script>
export default {
  name: "LeaderboardItem",
  props: {
    movie: {
      type: Object,
      required: true,
    },
    rank: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      delayedRank: this.rank,
      newStep: "",
      info: null,
    };
  },
  mounted() {
    this.getMovie(this.movie).then(info => {
      this.info = info
    })
  },
  watch: {
    rank() {
      setTimeout(() => {
        this.delayedRank = this.rank;
      }, 4000);
    },
    selected() {
      if (this.selected) {
        this.focus();
      }
    },
  },
  methods: {
    focus() {
      this.$refs.newScore.focus();
    },
    typeScore(e) {
      if (e.code === "Escape") {
        this.newStep = "";
        this.$emit("escape");
      }
      if (e.code === "Enter") {
        if (this.newStep === "") {
          return;
        }
        this.$emit("step", {
          movieId: this.movie.id,
          step: parseInt(this.newStep, 10),
        });
        this.newStep = "";
      }
    },
    async getMovie(movie) {
      const url = new URL('https://api.themoviedb.org/3/search/movie?api_key=64a015ee94412bef16ea9ef83bf3b8e8')
      url.searchParams.append('query', this.movie.name)
      url.searchParams.append('language', 'de-DE')
      if (this.movie.year) {
        url.searchParams.append('year', this.movie.year)
      }
      return await fetch(url.toString()).then(res => res.json()).then(data => data.results[0])
    },
  },
};
</script>

<style>
.leaderboard__item {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  background: linear-gradient( 45deg, #791218 20%, #911b20 100% );
  padding: 10px;
  display: flex;
  border: 2px solid #911b20;
  color: white;
}

.leaderboard__item:first-of-type {
  background: linear-gradient(45deg, #ffd700 20%, #ffaa0d);
  color: #000;
  font-weight: bold;
  border: #ffd700;
  box-shadow: 0 0 58px -10px gold;
}

.leaderboard__item:nth-of-type(2) {
  background: linear-gradient(45deg, silver 20%, #757575);
  color: #000;
  font-weight: bold;
  border: #757575;
  box-shadow: 0 0 58px -10px silver;
}

.leaderboard__item:nth-of-type(3) {
  background: linear-gradient(45deg, #c06722 20%, saddlebrown);
  color: #000;
  font-weight: bold;
  border: #c06722;
  box-shadow: 0 0 58px -10px #c06722;
}

.leaderboard__item.selected {
  outline: 2px solid rgba(255, 255, 100, 0.4);
}

.leaderboard__rank {
  font-weight: bold;
  width: 20px;
  display: inline-block;
}

.leaderboard__movie {
  text-align: left;
  text-transform: uppercase;
  margin-left: 5px;
}

.leaderboard__score {
  text-align: right;
  font-weight: bold;
  float: right;
}

.leaderboard__buttons {
  display: flex;
  justify-content: center;
  width: 30%;
}

.leaderboard__button {
  background: #007bff;
  border: 1px solid #007bff;
  color: #fff;
  cursor: pointer;
  height: 2rem;
  line-height: 1;
  margin: 0 0.1rem;
  width: 2rem;
}

.leaderboard__button--disabled {
  background: #eee;
  border: 1px solid #ccc;
  color: #ccc;
  cursor: auto;
}

.leaderboard__meta {
  display: flex;
  gap: 10px;
  align-items: center;
}

.leaderboard__poster img {
  max-height: 50px;
}
</style>
